// extracted by mini-css-extract-plugin
export var websiteRedesignCardBusinessSection = "q_bW";
export var websiteRedesignCaseSection = "q_b5";
export var websiteRedesignExpertiseSection = "q_b0";
export var websiteRedesignIndustriesSection = "q_bX";
export var websiteRedesignPracticesSection = "q_b2";
export var websiteRedesignPreferencesSection = "q_bZ";
export var websiteRedesignPrimeSection = "q_bT";
export var websiteRedesignProcessSection = "q_bY";
export var websiteRedesignProjLogoSection = "q_b3";
export var websiteRedesignServicesSection = "q_bV";
export var websiteRedesignTestimonialPost = "q_b1";
export var websiteRedesignWebDesignCasesSection = "q_b4";